import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  useTheme,
} from '@mui/material'
import LogoText from '@UI/sharedComponents/LogoText'
import { useIsMobile } from '@liv-frontend/ui'
import { useTranslations } from 'next-intl'

const { useRouter } = getLivSharedPathnamesNavigation()

const Footer = () => {
  const theme = useTheme()
  const t = useTranslations('Footer')
  const router = useRouter()
  const isMobile = useIsMobile()

  return (
    <footer
      style={{
        backgroundColor: theme.palette.primary.contrastText,
        marginTop: 'auto',
      }}
    >
      <Container sx={{ pt: 4 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <LogoText variant="h4" />

          <Box
            sx={{
              width: isMobile ? '100%' : 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              mt: 1,
            }}
          >
            <Link
              underline="none"
              component="a"
              href="/info/terminos-de-uso"
              target="blank"
              color="text.primary"
              variant="subtitle2"
            >
              {t('Terms')}
            </Link>
            <Link
              underline="none"
              component="a"
              href="/info/politica-privacidad"
              target="blank"
              color="text.primary"
              variant="subtitle2"
            >
              {t('Privacy')}
            </Link>
            <Button
              variant="outlined"
              onClick={() => router.push('/user/sign-in/')}
              sx={{ minWidth: '100px' }}
            >
              {t('Login')}
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 3, mb: 8 }}>
          <Typography
            align="center"
            variant="subtitle2"
            color="text.secondary"
            gutterBottom
            component="p"
          >
            &copy; {new Date().getFullYear()} Liv Marketplace.{' '}
            {t('AllRightsReserved')}
          </Typography>
          <Typography
            align="center"
            variant="caption"
            color="text.secondary"
            component="p"
          >
            {t('Cookies')}
          </Typography>
        </Box>
      </Container>
    </footer>
  )
}

export default Footer
