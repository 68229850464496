import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import TextWithLogoReplaced from '@UI/sharedComponents/TextWithLogoReplaced'

import { useTranslations } from 'next-intl'

function Reviews(): JSX.Element {
  const theme = useTheme()
  const t = useTranslations('LandingPage.HomePage.Reviews')

  const mock = [
    {
      feedback: t('Review1.Description'),
      name: t('Review1.Name'),
      title: t('Review1.Position'),
      avatar: '/assets/images/landing/review1.png',
    },
    {
      feedback: t('Review2.Description'),
      name: t('Review2.Name'),
      title: t('Review2.Position'),
      avatar: '/assets/images/landing/review2.png',
    },
    {
      feedback: t('Review3.Description'),
      name: t('Review3.Name'),
      title: t('Review3.Position'),
      avatar: '/assets/images/landing/review3.png',
    },
  ]

  return (
    <Box sx={{ backgroundColor: 'white', py: 12 }}>
      <Container>
        <Box marginBottom={4}>
          <Typography variant="h3" sx={{ textAlign: 'center', mb: 2 }}>
            {t('Title')}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key -- Key needed
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={1}
                height={1}
                data-aos="fade-up"
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
                component={Card}
                display="flex"
                flexDirection="column"
                alignItems="center"
                boxShadow={0}
                sx={{
                  backgroundColor: i === 1 ? '#EEF8FA' : 'white',
                  py: 4,
                  px: 2,
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box mb={1}>
                    <Box
                      component="svg"
                      width={48}
                      height={48}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      color={theme.palette.primary.main}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.3}
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </Box>
                  </Box>
                  <TextWithLogoReplaced
                    text={item.feedback}
                    align="center"
                    mb={3}
                    textAlign="center"
                    color="#646E73"
                    fontSize="16px"
                  />
                  <Box display="flex" justifyContent="center">
                    {[1, 2, 3, 4, 5].map((index) => (
                      <Box key={index} sx={{ color: '#F9B934' }}>
                        <svg
                          width={24}
                          height={24}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ paddingBottom: 2 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                    <ListItemAvatar>
                      <Avatar src={item.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                    />
                  </ListItem>
                </CardActions>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Reviews
