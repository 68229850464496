import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import TextWithLogoReplaced from '@UI/sharedComponents/TextWithLogoReplaced'

import { useTranslations } from 'next-intl'

import { useIsMobile } from '@liv-frontend/ui'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

const { useRouter } = getLivSharedPathnamesNavigation()

function Products(): JSX.Element {
  const isMobile = useIsMobile()
  const theme = useTheme()
  const router = useRouter()
  const t = useTranslations('LandingPage.HomePage.Products')

  const mock = [
    {
      secondary: t('Product2.Label'),
      title: t('Product2.Title'),
      description: t('Product2.Description'),
      listItems: [
        t('Product2.List1'),
        t('Product2.List2'),
        t('Product2.List3'),
        t('Product2.List4'),
        t('Product2.List5'),
        t('Product2.List6'),
      ],
      illustration: '/assets/images/landing/benefits2.png',
      primaryButtonText: t('Product2.PrimaryButtonText'),
      primaryButtonUrl:
        '/info/sample/investigations/result/single/497b93aa-ec93-7164-b0a2-6b6bc003f55b/',
      secondaryButtonText: t('Product2.SecondaryButtonText'),
      secondaryButtonUrl: '/products/investigations/',
    },
    {
      secondary: t('Product1.Label'),
      title: t('Product1.Title'),
      description: t('Product1.Description'),
      listItems: [
        t('Product1.List1'),
        t('Product1.List2'),
        t('Product1.List3'),
        t('Product1.List4'),
        t('Product1.List5'),
        t('Product1.List6'),
      ],
      illustration: '/assets/images/landing/benefits1.png',
      primaryButtonText: t('Product1.PrimaryButtonText'),
      primaryButtonUrl:
        'https://www.liv.place/es-MX/info/sample/legal-policies/details/21cc1e79-8dfa-481d-8142-e9b1dc338e21/',
      secondaryButtonText: t('Product1.SecondaryButtonText'),
      secondaryButtonUrl: 'https://www.liv.place/es-MX/products/legal-policy/',
    },

    // Liv Express hidden for now
    // {
    //   secondary: t('Product3.Label'),
    //   title: t('Product3.Title'),
    //   description: t('Product3.Description'),
    //   listItems: [
    //     t('Product3.List1'),
    //     t('Product3.List2'),
    //     t('Product3.List3'),
    //   ],
    //   illustration: '/assets/images/landing/benefits3.png',
    //   primaryButtonText: t('Product3.PrimaryButtonText'),
    //   primaryButtonUrl: '',
    //   secondaryButtonText: t('Product3.SecondaryButtonText'),
    //   secondaryButtonUrl: '',
    // },
  ]

  return (
    <Box sx={{ width: '100%', backgroundColor: '#EEF8FA', py: 12 }}>
      <Container>
        <Typography variant="h3" sx={{ textAlign: 'center', mb: 2 }}>
          {t('Title')}
        </Typography>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid
              data-aos="fade-up"
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
              key={item.title}
              item
              container
              xs={12}
              spacing={4}
              direction={i % 2 === 1 ? 'row' : 'row-reverse'}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                xs={12}
                sm={6}
              >
                <Box sx={{ maxWidth: '480px' }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      textAlign: isMobile ? 'center' : 'left',
                      color: theme.palette.secondary.main,
                    }}
                  >
                    {item.secondary}
                  </Typography>
                  <TextWithLogoReplaced
                    text={item.title}
                    variant={isMobile ? 'h5' : 'h4'}
                    textAlign={isMobile ? 'center' : 'left'}
                  />
                  <Typography
                    variant={isMobile ? 'body2' : 'body1'}
                    color="text.secondary"
                    sx={{ mt: isMobile ? 2 : 1 }}
                  >
                    {item.description}
                  </Typography>
                  <ul
                    style={{
                      color: theme.palette.text.secondary,
                      paddingLeft: '24px',
                      fontSize: isMobile ? '15px' : '16px',
                    }}
                  >
                    {item.listItems.map((listItem, index) => (
                      // eslint-disable-next-line react/no-array-index-key -- We are using the index as key because the list is static
                      <li key={index}>{listItem}</li>
                    ))}
                  </ul>
                  <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
                    <Button
                      variant="contained"
                      fullWidth={isMobile}
                      onClick={() => router.push(item.primaryButtonUrl)}
                      sx={{ textTransform: 'none', minWidth: '135px' }}
                    >
                      {item.primaryButtonText}
                    </Button>
                    <Button
                      variant="outlined"
                      fullWidth={isMobile}
                      onClick={() => router.push(item.secondaryButtonUrl)}
                      sx={{ textTransform: 'none', minWidth: '135px' }}
                    >
                      {item.secondaryButtonText}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                container
                justifyContent={i % 2 === 1 ? 'center' : 'flex-end'}
                alignItems="center"
                xs={12}
                sm={6}
                sx={{
                  order: isMobile ? -1 : 'auto',
                  pr: i % 2 === 1 && !isMobile ? '56px' : '0',
                }}
              >
                <Box
                  component="img"
                  src={item.illustration}
                  alt={item.title}
                  width={1}
                  sx={{
                    mx: i % 2 === 1 ? '0' : 'auto',
                    maxWidth: i % 2 === 1 ? '360px' : '420px',
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Products
