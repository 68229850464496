'use client'

import type { TypographyProps } from '@mui/material/Typography'
import Typography from '@mui/material/Typography'
import LogoText from '@UI/sharedComponents/LogoText'
import React from 'react'

export interface TextWithLogoReplacedProps extends TypographyProps {
  text: string
}

const TextWithLogoReplaced = ({
  text,
  ...props
}: TextWithLogoReplacedProps) => {
  const regex = /liv/gi
  const matches = text.match(regex)

  if (!matches) {
    return <Typography {...props}>{text}</Typography>
  }

  const parts = text.split(regex)
  const elements = []

  for (let i = 0; i < parts.length; i++) {
    elements.push(parts[i])

    if (i < parts.length - 1) {
      elements.push(<LogoText {...props} key={`logo-${i}`} component="span" />)
    }
  }

  return <Typography {...props}>{elements}</Typography>
}
export default TextWithLogoReplaced
