import React from 'react'
import type { NumericFormatProps } from 'react-number-format'
import { NumericFormat } from 'react-number-format'

type CustomProps = {
  onChange: (event: {
    target: { name: string; value: number | undefined }
  }) => void
  name: string
}

const PriceNoDecimalsThousandsSeparatorMask = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        const parsedValue = parseFloat(values.value.replace(/[$,]/g, ''))
        const val = Number.isNaN(parsedValue) ? undefined : parsedValue
        onChange({
          target: {
            name: props.name,
            value: val,
          },
        })
      }}
      thousandSeparator
      decimalScale={0}
      fixedDecimalScale
      valueIsNumericString
      allowNegative={false}
      prefix="$"
      type="text"
      displayType="input"
    />
  )
})

export default PriceNoDecimalsThousandsSeparatorMask
