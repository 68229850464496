import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
  useTheme,
} from '@mui/material'
import TextWithLogoReplaced from '@UI/sharedComponents/TextWithLogoReplaced'
import { useIsMobile } from '@liv-frontend/ui'
import { useTranslations } from 'next-intl'

interface FaqGroupItemProps {
  items: Array<{
    title: string
    subtitle: string
  }>
}

const FaqGroupItem = ({ items }: FaqGroupItemProps): JSX.Element => {
  const theme = useTheme()
  return (
    <Box>
      {items.map((item, i) => (
        <Box
          component={Accordion}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          padding={1}
          marginBottom={2}
          borderRadius={`${theme.spacing(1)} !important`}
          sx={{
            '&::before': {
              display: 'none',
            },
          }}
        >
          <Box
            component={AccordionSummary}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id={`panel1a-header--${i}`}
            sx={{
              '&.Mui-expanded': {
                color: theme.palette.primary.dark,
              },
              color: '#2D3748',
            }}
          >
            <TextWithLogoReplaced
              text={item.title}
              variant="subtitle1"
              fontWeight={700}
            />
          </Box>
          <AccordionDetails>
            <TextWithLogoReplaced
              text={item.subtitle}
              color="#2D3748"
              fontSize="14px"
              whiteSpace="pre-line"
            />
          </AccordionDetails>
        </Box>
      ))}
    </Box>
  )
}

const FAQ = () => {
  const isMobile = useIsMobile()
  const t = useTranslations('LandingPage.HomePage.FAQ')

  return (
    <Box sx={{ background: 'white', py: isMobile ? 4 : 12 }}>
      <Container sx={{ py: 4 }}>
        <Typography
          variant={isMobile ? 'h4' : 'h3'}
          sx={{ textAlign: 'center', mb: 2 }}
        >
          {t('Title')}
        </Typography>
        <FaqGroupItem
          items={[
            {
              title: t('Q1.Question'),
              subtitle: t('Q1.Answer'),
            },
            {
              title: t('Q2.Question'),
              subtitle: t('Q2.Answer'),
            },
            {
              title: t('Q3.Question'),
              subtitle: t('Q3.Answer'),
            },
            {
              title: t('Q4.Question'),
              subtitle: t('Q4.Answer'),
            },
            {
              title: t('Q5.Question'),
              subtitle: t('Q5.Answer'),
            },
          ]}
        />
      </Container>
    </Box>
  )
}

export default FAQ
